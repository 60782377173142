<template>

  <div class="flex flex-col w-full">

    <div class="flex flex-col items-center">

      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        About Us
      </div>

      <div class="mt-4">
        <img
          src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/about_header.jpg"
          alt=""
        >
      </div>

      <div class="flex max-w-screen-lg text-center mt-4">
        Established in Telford, Shropshire, Printotype is able to provide manufacturing services in the whole of the West Midlands area, and our great transport links allow us to rapidly supply products further afield to anywhere within the United Kingdom.
      </div>

      <div class="max-w-screen-lg">
        <div class="text-left mt-16 text-pt-gray font-bold text-2xl">
          Our Company
        </div>
        <div>
          <p class="my-4">
            Printotype Ltd was set up to dual wield the incredible capabilities of <strong>CAD / 3D Design</strong> and <strong>Rapid Prototyping / 3D Printing</strong>. This gives us the unique and advantageous position of being able to actively design and produce CAD and 3D Designs, and then using our in house 3D Printing systems, convert these into a physical prototype if required.
          </p>
          <p class="my-4">
            Over the past few years i've had the opportunities to work within many different fields in the uk and overseas, and seen a wide array of design practices, from developing advanced physiotherapy systems, to working on large scale coordinated infrastructure projects. During that time 3D Printing has gradually been gaining global recognition, and now is true to say that nearly any component, part, or invention can be converted into a real world product in hours or days, and almost eradicating the notoriously high cost of manufacturing tooling. We can offer a simple, fast and pain free introduction to the world of 3D Printing and Rapid Prototyping.
          </p>
        </div>

        <div class="text-left mt-16 text-pt-gray font-bold text-xl">
          Concept Models through to Functional Working Prototypes
        </div>
        <div>
          <p class="my-4">
            By using 3d printing to rapidly produce components and semi scale parts, you can help reduce the time taken and lower costs in the initial stages of product development. Once your idea has moved on to functional testing, 3d printed prototypes can allow you to see how a concept fares in reality when performing its intended task, and rapidly modify and alter it until you perfect the design.
          </p>
        </div>

        <div class="text-left mt-16 text-pt-gray font-bold text-xl">
          Small Volume Production
        </div>
        <div>
          <p class="my-4">
            3D Printing comes with the benefits of zero tooling and virtually non existent lead time, making it ideal for producing small quantities of parts. Perhaps you operate an assembly facility that runs into a significant delay on a particular component - 3D Printing could allow a low volume run of replacement parts to be made and used whilst new stock was making its way to the facility.
          </p>
        </div>

        <div class="text-left mt-16 text-pt-gray font-bold text-xl">
          Manufacturing Tools
        </div>
        <div>
          <p class="my-4">
            3D Printing provides both a fast and precise method of producing manufacturing tooling, fixtures and jigs. The speed of 3D Design and 3D printing allows both us and clients to evaluate the performance of a tool and make adjustments for maximum performance or improved ergonomics in both a quick and highly cost effective manner.
          </p>
          <p class="my-4">
            If you would like to know more about the work we can do, both in terms of 3d Design as well as 3D Printing, then please have a look at some of the downloadable documents available below
          </p>

          <ul class="list-disc ml-4">
            <li>Printotype Ltd - Company Profile PDF</li>
            <li>Manufacturing & 3D Printing PDF</li>
            <li>Creative & Design PDF</li>
          </ul>
        </div>

        <div class="flex">
          <div>
            <div class="text-left mt-16 text-pt-gray font-bold text-xl">
              Our Key Principles
            </div>
            <div class="mb-24">
              <ul class="list-disc ml-4">
                <li>Fast and polite customer liason</li>
                <li>We support a wide variety of file formats such as (.DWG, .DxF, .Stl, .iges) and so can work with a variety of software.</li>
                <li>Bespoke Design work is available upon request from our experienced design team, including Structural Steelwork.</li>
                <li>County-wide delivery within 12 hours of printing, Nationwide guaranteed within 24 hours.</li>
                <li>International orders welcomed.</li>
                <li>Wide variety of materials and finishes to choose from, as well as colours.</li>
              </ul>
            </div>
          </div>
          <div class="p-4">
            <img
              src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/about_footer.jpg" alt=""
              class="rounded-lg"
            >
          </div>
        </div>


      </div>

    </div>
  </div>

</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  }
}
</script>